import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import PageBuilder from "../modules/page-builder";
import Header from "../modules/header";
import Related from "../modules/related";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      excerpt
      slug {
        current
      }
      ...pageBuilderFields
      heroBackground
      heroTitle
      heroText
      image {
        asset {
          _id
          altText
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        hotspot {
          height
          width
          x
          y
        }
      }
      related {
        ... on SanityArticle {
          title
          path
          _type
          articleType
          taxonomy {
            topic {
              title
            }
          }
        }
        ... on SanityPolicySubmission {
          title
          path
          _type
          articleType
          taxonomy {
            topic {
              title
            }
          }
        }
        ... on SanityEvent {
          _type
          title
          dateEnd
          dateStart
          eventType
          location
          path
        }
        ... on SanityPage {
          _type
          title
          excerpt
          path
        }
      }
      parentPage {
        ... on SanityPage {
          _id
        }
        ... on SanityPagePhotoComp {
          _id
        }
        ... on SanityPageNews {
          _id
        }
        ... on SanityPageAlumni {
          _id
        }
        ... on SanityPagePolicy {
          _id
        }
        ... on SanityPageContact {
          _id
        }
        ... on SanityPageEvents {
          _id
        }
      }
      breadcrumbs {
        title
        slug
      }
      seo {
        title
        description
        keywords
        image {
          asset {
            _id
            altText
          }
        }
      }
      taxonomy {
        topic {
          title
          parentTopic
        }
        tags {
          title
        }
      }
    }
  }
`;

const PageTemplate = props => {
  const { data } = props;
  const page = data.page;

  return (
    <Layout className="pages">
      <SEO
        title={page.title}
        excerpt={page.excerpt}
        image={page.image}
        seo={page.seo}
        taxonomy={page.taxonomy}
      />
      <Header
        title={page.heroTitle || page.title}
        breadcrumbs={page.breadcrumbs}
        image={page.image}
        text={page.heroText}
        backgroundColour={
          page.parentPage?._id ? "beige" : page.heroBackground || "orange"
        }
      />
      <PageBuilder builder={page.pageBuilder} />
      {page.related?.[0] && <Related related={page.related} />}
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object,
};

export default PageTemplate;
